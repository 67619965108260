
<template>
    <b-container fluid>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('financialAid.farmer_selection') }}</h4>
          </template>
          <template v-slot:body>
              <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('globalTrans.fiscal_year')"
                      label-for="fiscal_year_id"
                      >
                      <b-form-select
                          plain
                          v-model="search.fiscal_year_id"
                          :options="fiscalList"
                          id="fiscal_year_id"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('dae_config.season_name')"
                      label-for="season_id"
                      >
                      <b-form-select
                          plain
                          v-model="search.season_id"
                          :options="seasonSetupList"
                          id="season_id"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('dae_config.circular_name')"
                    label-for="circular_id"
                    >
                    <b-form-select
                        plain
                        v-model="search.circular_id"
                        :options="circularList"
                        id="circular_id"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('org_pro_district.district')"
                    label-for="district_id"
                    >
                    <b-form-select
                        plain
                        v-model="search.district_id"
                        :options="districtList"
                        id="district_id"
                        :disabled="isReadOnly"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('org_pro_upazilla.upazilla')"
                    label-for="upazilla_id"
                    >
                    <b-form-select
                        plain
                        v-model="search.upazilla_id"
                        :options="upazillaList"
                        id="upazilla_id"
                        :disabled="isReadOnly"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                </b-col>
              </b-row>
          </template>
        </iq-card>
        <b-row>
            <b-col md="12">
              <iq-card>
                <template v-slot:headerTitle>
                  <h4 class="card-title">{{ $t('financialAid.farmer_selection') }} {{ $t('globalTrans.list') }}</h4>
                </template>
                <template v-slot:headerAction>
                  <router-link :class="'btn btn_add_new'" to="/incentive-grant-service/dae/financial-aid/farmer-selection-form">
                    <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                  </router-link>
                </template>
                <template v-slot:body>
                  <b-overlay :show="loadingState">
                      <b-row>
                          <b-col md="12" class="table-responsive">
                              <b-table thead-class="table_head" striped :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                  <template v-slot:cell(index)="data">
                                      {{ $n(data.index + pagination.slOffset) }}
                                  </template>
                                  <template v-slot:cell(action)="data">
                                    <!-- <router-link :to="'/incentive-grant-service/dae/financial-aid/farmer-selection-form?id='+ data.item.id" variant=" iq-bg-success mr-1 mb-1" size="sm" class="btn btn-sm btn-success mr-1"><i class="ri-ball-pen-fill m-0"></i></router-link> -->
                                    <a href="javascript:" class="btn_table_action table_action_" title="View" v-b-modal.modal-5 @click="details(data.item)"><i class="fas fa-eye"></i></a>
                                    <a href="javascript:" class="btn_table_action table_action_" v-if="data.item.payroll.length === 0" v-b-modal.modal-payroll @click="details(data.item)" title="Prepare Payroll"><i class="fas fa-arrow-right"></i></a>
                                  </template>
                              </b-table>
                              <b-pagination
                                align="center"
                                v-model="pagination.currentPage"
                                :per-page="pagination.perPage"
                                :total-rows="pagination.totalRows"
                                @input="searchData"
                              />
                          </b-col>
                      </b-row>
                  </b-overlay>
                </template>
              </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-payroll" size="xl" :title="$t('dae_subsidy.preparePayroll')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Payroll :item="item" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-5" size="xl" :title="$t('financialAid.farmer_list')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :item="item" :key="item"></Details>
        </b-modal>
    </b-container>
</template>
<script>
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { farmerSelectList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Details from '../FarmerDetails'
import Payroll from './Payroll'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Details, Payroll
  },
  data () {
    return {
      search: {
        fiscal_year_id: 0,
        season_id: 0,
        circular_id: 0,
        circular_type: 0,
        district_id: 0,
        upazilla_id: 0
      },
      upazillaList: [],
      isReadOnly: false,
      rows: [],
      item: '',
      itemId: 0,
      seasons: [
        {
          value: 1,
          text_en: 'Robi',
          text_bn: 'রবি'
        },
        {
          value: 2,
          text_en: 'Kharif-1',
          text_bn: 'খরিফ-১'
        },
        {
          value: 3,
          text_en: 'Kharif-2',
          text_bn: 'খরিফ-২'
        }
      ]
    }
  },
  computed: {
    authUser: function () {
      return this.$store.state.Auth.authUser
    },
    districtList: function () {
      return this.$store.state.commonObj.districtList
    },
    circularList: function () {
      const dataLlist = this.$store.state.incentiveGrant.commonObj.circularList.filter(item => item.status === 1)
      return dataLlist.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    },
    seasonSetupList: function () {
      return this.seasons.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    fiscalList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    formTitle () {
      return this.$t('financialAid.farmer_selection') + ' ' + this.$t('globalTrans.entry')
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
        { label: this.$t('financialAid.fiscal_year'), class: 'text-center' },
        { label: this.$t('financialAid.season'), class: 'text-center' },
        { label: this.$t('dae_config.circular_name'), class: 'text-center' },
        { label: this.$t('org_pro_district.district'), class: 'text-center' },
        { label: this.$t('org_pro_upazilla.upazilla'), class: 'text-center' },
        { label: this.$t('globalTrans.action'), class: 'text-center' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year_bn' },
          { key: 'season_bn' },
          { key: 'circular_name_bn' },
          { key: 'district_name_bn' },
          { key: 'upazilla_name_bn' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year' },
          { key: 'seasson' },
          { key: 'circular_name' },
          { key: 'district_name' },
          { key: 'upazilla_name' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.district_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.upazillaList = this.getUpazilaList(newVal)
      }
    }
  },
  mounted () {
    if (this.authUser.role_id !== 1 && this.authUser.is_org_admin === 0) {
        this.isReadOnly = true
        this.search.district_id = this.authUser.office_detail.district_id
        this.search.upazilla_id = this.authUser.office_detail.upazilla_id
      }
    this.loadData()
  },
  methods: {
    getSeasonSetupList (fiscalYearId = null) {
      const seasonSetupList = this.$store.state.incentiveGrant.commonObj.seasonSetupList
      if (fiscalYearId) {
        return seasonSetupList.filter(item => item.fiscal_year_id === parseInt(fiscalYearId))
      }
      return seasonSetupList
    },
    searchData () {
      this.loadData()
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, farmerSelectList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
      const fiscalYearList = this.$store.state.commonObj.fiscalYearList
      const circularList = this.$store.state.incentiveGrant.commonObj.circularList
      const divisionList = this.$store.state.commonObj.divisionList
      const districtList = this.$store.state.commonObj.districtList
      const upazilaList = this.$store.state.commonObj.upazilaList
      const listData = data.map(item => {
        const fiscalObject = fiscalYearList.find(agmt => agmt.value === item.fiscal_year_id)
        const circularObj = circularList.find(cr => cr.value === item.circular_id)
        const seasonObject = this.seasonSetupList.find(agmt => agmt.value === item.season_id)
        const districtObject = districtList.find(dst => dst.value === item.district_id)
        const divisionObject = divisionList.find(div => div.value === districtObject.division_id)
        const upazillaObject = upazilaList.find(upz => upz.value === item.upazilla_id)

        const fiscalyearData = {
          fiscal_year: fiscalObject !== undefined ? fiscalObject.text_en : '',
          fiscal_year_bn: fiscalObject !== undefined ? fiscalObject.text_bn : ''
        }
        const circularData = {
          circular_name: circularObj !== undefined ? circularObj.text_en : '',
          circular_name_bn: circularObj !== undefined ? circularObj.text_bn : ''
        }
        const seasonData = {
          seasson: seasonObject !== undefined ? seasonObject.text_en : '',
          season_bn: seasonObject !== undefined ? seasonObject.text_bn : ''
        }
        const divisionData = {
          division_id: divisionObject.value,
          division_name: divisionObject !== undefined ? divisionObject.text_en : '',
          division_name_bn: divisionObject !== undefined ? divisionObject.text_bn : ''
        }
        const districtData = {
          district_name: districtObject !== undefined ? districtObject.text_en : '',
          district_name_bn: districtObject !== undefined ? districtObject.text_bn : ''
        }
        const upazillaData = {
          upazilla_name: upazillaObject !== undefined ? upazillaObject.text_en : '',
          upazilla_name_bn: upazillaObject !== undefined ? upazillaObject.text_bn : ''
        }

        return Object.assign({}, item, fiscalyearData, circularData, seasonData, divisionData, districtData, upazillaData)
      })
      return listData
    },
    details (item) {
      this.item = item
    },
    getUpazilaList (districtId = null) {
        const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0 && item.district_id === districtId)
        return upazilaList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    }
  }
}
</script>
