<template>
  <b-container fluid>
    <b-row>
        <b-col lg="12" sm="12">
            <iq-card>
                <template v-slot:body>
                    <b-overlay :show="loading">
                        <b-row>
                            <b-col lg="12" sm="12">
                                <template>
                                    <div style="font-size:12px; background-color: #337982;">
                                        <h5 class="text-white text-center"> {{ $t('admission_form.gen_inf') }}</h5>
                                    </div>
                                </template>
                                <b-table-simple striped bordered small class="mt-2">
                                    <b-tr>
                                        <b-th>{{$t('globalTrans.division')}}</b-th>
                                        <b-td> {{ locale === 'en' ? farSelect.division_name : farSelect.division_bn }} </b-td>
                                        <b-th>{{$t('globalTrans.district')}}</b-th>
                                        <b-td> {{ locale === 'en' ? farSelect.district : farSelect.district_bn }} </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{$t('org_pro_upazilla.upazilla')}}</b-th>
                                        <b-td> {{ locale === 'en' ? farSelect.upazilla : farSelect.upazilla_bn }} </b-td>
                                        <b-th>{{ $t('dae_subsidy.fiscal_year') }}</b-th>
                                        <b-td> {{ locale === 'en' ? fiscalYear.text : fiscalYear.text }} </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ $t('dae_subsidy.circular_ty') }}</b-th>
                                        <b-td> {{ getCircularType(4) }} </b-td>
                                        <b-th>{{ $t('dae_subsidy.circular_name') }}</b-th>
                                        <b-td> {{ locale === 'en' ? farSelect.circular_name : farSelect.circular_name_bn }} </b-td>
                                    </b-tr>
                                </b-table-simple>
                            </b-col>
                            <div class="col-sm-12 col-md-12">
                                <template>
                                    <div class="col-sm-12 col-md-12" style="font-size:12px; background-color: #337982; padding:6px">
                                    <h5 class="text-white text-center"> {{ $t('financialAid.farmer_list') }}</h5>
                                    </div>
                                </template>
                                <b-table-simple striped bordered small class="mt-2">
                                    <b-tr class="text-center">
                                        <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                        <b-th>{{ $t('globalTrans.name') }}</b-th>
                                        <b-th>{{ $t('financialAid.farmer_father_name') }}</b-th>
                                        <b-th>{{ $t('dae_subsidy.nid') }}</b-th>
                                        <b-th>{{ $t('globalTrans.mobile') }}</b-th>
                                        <b-th>{{ $t('common_config.account_type')}}</b-th>
                                        <b-th>{{ $t('common_config.account_no')}}</b-th>
                                        <b-th>{{ $t('bsri_demandDIS.bank')}}</b-th>
                                        <b-th>{{ $t('bsri_demandDIS.branch')}}</b-th>
                                        <b-th>{{ $t('rehabilitaionAllocation.cash_tk')}}</b-th>
                                    </b-tr>
                                    <b-tr v-for="(detail,index) in applicants" :key="index">
                                        <b-td>{{ $n(index+1) }}</b-td>
                                        <b-td>{{ ($i18n.locale === 'bn') ? detail.farmer_name_bn : detail.farmer_name }}</b-td>
                                        <b-td>{{ ($i18n.locale === 'bn') ? detail.father_name_bn : detail.father_name }}</b-td>
                                        <b-td>{{ $n(detail.nid_no, { useGrouping: false }) }}</b-td>
                                        <b-td>{{ detail.mobile_no | mobileNumber }}</b-td>
                                        <b-td>{{ detail.account_type_name }}</b-td>
                                        <b-td>{{ detail.farmer.ac_category_id == 1 ? $n(detail.farmer.wallet_no, { useGrouping: false }) : $n(detail.farmer.account_no, { useGrouping: false }) }}</b-td>
                                        <b-td>{{ detail.farmer.ac_category_id == 1 ? detail.ac_type_name : detail.bank_name }}</b-td>
                                        <b-td>{{ detail.branch_name ? detail.branch_name : '-' }}</b-td>
                                        <b-td class="text-right">{{ $n(detail.amount, { useGrouping: false }) }}</b-td>
                                    </b-tr>
                                    <b-tr v-if="applicants.length !== 0" style="font-weight:bold">
                                        <b-td colspan="9" class="text-right">{{ $t('globalTrans.total') }}</b-td>
                                        <b-td class="text-right">{{ $n(grandTotal, { useGrouping: false }) }}</b-td>
                                    </b-tr>
                                </b-table-simple>
                            </div>
                        </b-row>
                        <div class="row">
                            <div class="col-sm-6 col-md-12">
                                <div class="text-right">
                                    <b-button type="submit" variant="primary" class="mr-2" @click="save">{{ $t('globalTrans.submit') }}</b-button>
                                        &nbsp;
                                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-payroll')">{{ $t('globalTrans.cancel') }}</b-button>
                                </div>
                            </div>
                        </div>
                    </b-overlay>
                </template>
            </iq-card>
        </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { getFinancialAidFarmer, financialAidPrepare } from '../../api/routes'
export default {
    props: ['item'],
    data () {
        return {
            grandTotal: 0,
            allocationAmount: 0,
            farSelect: {},
            applicants: [],
            loading: false,
            fiscalYear: '',
            form: {
                details: []
            },
            accountTypeList: [
              { value: 1, text_en: 'Mobile Banking', text_bn: 'মোবাইল ব্যাংকিং' },
              { value: 2, text_en: 'Bank', text_bn: 'ব্যাংক' }
            ]
        }
    },
    created () {
        if (this.item) {
            this.farSelect = this.getRelationalData(this.item)
            this.getApplicantList(this.item.id)
        }
    },
    computed: {
        locale: function () {
            return this.$i18n.locale
        }
    },
    methods: {
        getApplicantList (itemId) {
            this.loading = true
            RestApi.getData(incentiveGrantServiceBaseUrl, `${getFinancialAidFarmer}/${itemId}`).then(response => {
                if (response.success) {
                    this.applicants = this.farmerRelationalData(response.farmers)
                }
                this.loading = false
            })
        },
        farmerRelationalData (data) {
            const listData = data.map((item, index) => {
                this.grandTotal += item.amount !== null ? item.amount : 0
                const deReqObj = {
                    far_general_info_id: item.far_general_info_id,
                    subs_demand_id: item.id,
                    land_quantity: item.land_quantity,
                    farmer_name: item.farmer.name,
                    farmer_name_bn: item.farmer.name_bn,
                    father_name: item.farmer.father_name,
                    father_name_bn: item.farmer.father_name_bn,
                    nid_no: item.farmer.nid_no,
                    mobile_no: item.farmer.mobile_no
                }
                const accountTypeData = this.accountTypeList.find(type => type.value === item.farmer.ac_category_id)
                const accountGroupData = { account_type_name: accountTypeData !== undefined ? (this.locale === 'bn' ? accountTypeData.text_bn : accountTypeData.text_en) : '' }
                const acTypeName = this.$store.state.commonObj.bankObj.bankList.find(acType => acType.value === item.farmer.ac_type_id)
                const acTypeData = { ac_type_name: acTypeName !== undefined ? (this.locale === 'bn' ? acTypeName.text_bn : acTypeName.text_en) : '' }
                const bankObj = this.$store.state.commonObj.bankObj.bankList.find(bank => bank.value === item.farmer.bank_id)
                const bankData = { bank_name: bankObj !== undefined ? (this.locale === 'bn' ? bankObj.text_bn : bankObj.text_en) : '' }
                const branchObj = this.$store.state.commonObj.bankObj.branchList.find(branch => branch.value === item.farmer.branch_id)
                const branchData = { branch_name: branchObj !== undefined ? (this.locale === 'bn' ? branchObj.text_bn : branchObj.text_en) : '' }
                return Object.assign({}, item, deReqObj, accountGroupData, acTypeData, bankData, branchData)
            })
            return listData
        },
        getCircularType (circularType) {
            const tmpCircularType = this.$store.state.incentiveGrant.commonObj.subsidyCircularTypeList.find(ct => ct.value === circularType)
            return typeof circularType !== 'undefined' ? (this.$i18n.locale === 'en' ? tmpCircularType.text_en : tmpCircularType.text_bn) : ''
        },
        getFiscalYear (fiscalyear) {
            const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(fiscal => fiscal.value === fiscalyear)
            if (this.$i18n.locale === 'bn') {
                return { value: fiscalYearObj.value, text: fiscalYearObj.text_bn }
            } else {
                return { value: fiscalYearObj.value, text: fiscalYearObj.text }
            }
        },
        getRelationalData (item) {
            const divisionList = this.$store.state.commonObj.divisionList
            const districtList = this.$store.state.commonObj.districtList
            const upazilaList = this.$store.state.commonObj.upazilaList
            const circularObj = this.$store.state.incentiveGrant.commonObj.circularList.find(circular => circular.value === item.circular_id)
            const divisionObject = divisionList.find(division => division.value === item.division_id)
            const districtObject = districtList.find(district => district.value === item.district_id)
            const upazillaObject = upazilaList.find(upazilla => upazilla.value === item.upazilla_id)
            this.fiscalYear = this.getFiscalYear(item.fiscal_year_id)
            const circularData = {
                circular_name: circularObj !== undefined ? circularObj.text_en : '',
                circular_name_bn: circularObj !== undefined ? circularObj.text_bn : ''
            }
            const divisionData = {
                division: divisionObject !== undefined ? divisionObject.text_en : '',
                division_bn: divisionObject !== undefined ? divisionObject.text_bn : ''
            }
            const districtData = {
                district: districtObject !== undefined ? districtObject.text_en : '',
                district_bn: districtObject !== undefined ? districtObject.text_bn : ''
            }
            const upazillaData = {
                upazilla: upazillaObject !== undefined ? upazillaObject.text_en : '',
                upazilla_bn: upazillaObject !== undefined ? upazillaObject.text_bn : ''
            }
            return Object.assign({}, item, circularData, divisionData, districtData, upazillaData)
        },
        async save () {
            const farDetails = []
            this.applicants.map(item => {
                const farInfo = { far_general_info_id: item.far_general_info_id, cash_tk: item.amount }
                farDetails.push(farInfo)
            })

            const formData = Object.assign(this.form, {
                fa_far_select_id: this.farSelect.id,
                fiscal_year_id: this.farSelect.fiscal_year_id,
                division_id: this.farSelect.division_id,
                district_id: this.farSelect.district_id,
                upazilla_id: this.farSelect.upazilla_id,
                circular_type: 4,
                circular_id: this.farSelect.circular_id,
                total_farmer: this.applicants.length,
                details: farDetails
            })

            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }
            result = await RestApi.postData(incentiveGrantServiceBaseUrl, financialAidPrepare, formData)
            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)
            this.loading = false
            if (result.success) {
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })
                this.$bvModal.hide('modal-payroll')
            } else {
                this.$toast.error({
                    title: this.$t('globalTrans.error'),
                    message: result.message,
                    color: '#fff'
                })
            }
        }
    }
}
</script>
