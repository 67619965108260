<template>
  <b-container fluid>
    <b-row>
        <b-col lg="12" sm="12">
            <iq-card>
                <template v-slot:body>
                    <b-overlay :show="loading">
                        <b-row>
                            <b-col lg="12" sm="12">
                                <template>
                                    <div style="font-size:12px; background-color: #337982;">
                                        <h5 class="text-white text-center"> {{ $t('admission_form.gen_inf') }}</h5>
                                    </div>
                                </template>
                                <b-table-simple striped bordered small class="mt-2">
                                  <b-tr>
                                      <b-th>{{ $t('financialAid.fiscal_year') }}</b-th>
                                      <b-td class="text-capitalize"> {{ currentLocale === 'en' ? form.fiscal_year : form.fiscal_year_bn }} </b-td>
                                      <b-th>{{ $t('financialAid.season') }}</b-th>
                                      <b-td class="text-capitalize">{{ currentLocale === 'en' ? form.season : form.season_bn }}</b-td>
                                  </b-tr>
                                  <b-tr>
                                      <b-th>{{ $t('dae_config.circular_name') }}</b-th>
                                      <b-td class="text-capitalize"> {{ currentLocale === 'en' ? form.circular_name : form.circular_name_bn }} </b-td>
                                      <b-th>{{ $t('org_pro_district.district') }}</b-th>
                                      <b-td class="text-capitalize"> {{ currentLocale === 'en' ? form.district_name : form.district_name_bn }} </b-td>
                                  </b-tr>
                                  <b-tr>
                                      <b-th>{{ $t('org_pro_upazilla.upazilla') }}</b-th>
                                      <b-td class="text-capitalize"> {{ currentLocale === 'en' ? form.upazilla_name : form.upazilla_name_bn }} </b-td>
                                  </b-tr>
                                </b-table-simple>

                                <template>
                                  <div style="font-size:12px; background-color: #337982;">
                                    <h5 class="text-white text-center"> {{ $t('financialAid.farmer_list') }}</h5>
                                  </div>
                                </template>
                              <b-table-simple striped bordered small class="mt-3">
                                <b-thead>
                                  <b-tr>
                                    <b-th class="text-center align-top">{{ $t('globalTrans.sl_no') }}</b-th>
                                    <b-th class="text-center align-top">{{ $t('globalTrans.name') }}</b-th>
                                    <b-th class="text-center align-top">{{ $t('financialAid.farmer_father_name') }}</b-th>
                                    <b-th class="text-center align-top">{{ $t('bsri_demandDIS.nid_number') }}</b-th>
                                    <b-th class="text-center align-top">{{ $t('common_config.far_wallet_no') }}</b-th>
                                    <b-th class="text-center align-top">{{ $t('globalTrans.mobile') }}</b-th>
                                    <b-th class="text-center align-top">{{$t('common_config.account_type')}}</b-th>
                                    <b-th class="text-center align-top">{{$t('common_config.account_no')}}</b-th>
                                    <b-th class="text-center align-top">{{$t('bsri_demandDIS.bank')}}</b-th>
                                    <b-th class="text-center align-top">{{$t('bsri_demandDIS.branch')}}</b-th>
                                    <b-th class="text-center align-top">{{ $t('globalTrans.amount') }} {{ $t('globalTrans.tk') }}</b-th>
                                  </b-tr>
                                </b-thead>
                                <b-tbody>
                                  <tr v-for="(detail, index) in form.details" :key="index">
                                    <td class="text-center">{{ $n(index + 1) }}</td>
                                    <td class="text-capitalize">{{ currentLocale === 'bn' ? detail.farmer.name_bn : detail.farmer.name }}</td>
                                    <td class="text-capitalize">{{ currentLocale === 'bn' ? detail.farmer.father_name_bn : detail.farmer.father_name }}</td>
                                    <td>{{ $n(detail.farmer.nid_no, { useGrouping: false }) }}</td>
                                    <td>{{ $n(detail.farmer.wallet_no, { useGrouping: false }) }}</td>
                                    <td>{{  detail.farmer.mobile_no | mobileNumber }}</td>
                                    <b-td>{{ detail.account_type_name }}</b-td>
                                    <b-td>{{ detail.farmer.ac_category_id == 1 ? detail.farmer.wallet_no : detail.farmer.account_no }}</b-td>
                                    <b-td>{{ detail.farmer.ac_category_id == 1 ? detail.ac_type_name : detail.bank_name }}</b-td>
                                    <b-td>{{ detail.branch_name }}</b-td>
                                    <td class="text-right">{{ detail.amount }}</td>
                                  </tr>
                                </b-tbody>
                              </b-table-simple>
                            </b-col>
                        </b-row>
                    </b-overlay>
                </template>
            </iq-card>
        </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { farmerSelectShow } from '../api/routes'

export default {
    props: ['item'],
    data () {
      return {
        id: this.item.id,
        loading: false,
        materials: [],
        form: {
          fiscal_year_id: 0,
          season_id: 0,
          circular_id: 0,
          details: []
        },
        accountTypeList: [
          { value: 1, text_en: 'Mobile Banking', text_bn: 'মোবাইল ব্যাংকিং' },
          { value: 2, text_en: 'Bank', text_bn: 'ব্যাংক' }
        ]
      }
    },
    created () {
      if (this.item) {
        this.getFormData()
      }
    },
    computed: {
      currentLocale: function () {
        return this.$i18n.locale
      },
      fiscalList: function () {
        return this.$store.state.commonObj.fiscalYearList
      },
      seasonSetupList: function () {
        return this.$store.state.incentiveGrant.commonObj.seasonList
      },
      circularList: function () {
        return this.$store.state.incentiveGrant.commonObj.circularList
      },
      cropList: function () {
        return this.$store.state.incentiveGrant.commonObj.cropList
      }
    },
    methods: {
      async getFormData () {
        this.loading = true
        await RestApi.getData(incentiveGrantServiceBaseUrl, `${farmerSelectShow}/${this.item.id}`)
        .then(response => {
          if (response.success) {
            this.form = this.getRelationalData(response.data)
            this.form.details = response.data.details
          }
          this.loading = false
        })
      },
      getRelationalData (data) {
        data.details = data.details.map((item) => {
          const deReqObj = {
              far_general_info_id: item.far_general_info_id,
              subs_demand_id: item.id,
              land_quantity: item.land_quantity,
              farmer_name: item.farmer.name,
              farmer_name_bn: item.farmer.name_bn,
              father_name: item.farmer.father_name,
              father_name_bn: item.farmer.father_name_bn,
              nid_no: item.farmer.nid_no,
              mobile_no: item.farmer.mobile_no,
              demandCollectioCropList: item.demand_collection_details,
              crops: [],
              material: []
          }
          const accountTypeData = this.accountTypeList.find(type => type.value === item.farmer.ac_category_id)
          const accountGroupData = { account_type_name: accountTypeData !== undefined ? (this.locale === 'bn' ? accountTypeData.text_bn : accountTypeData.text_en) : '' }
          const acTypeName = this.$store.state.commonObj.bankObj.bankList.find(acType => acType.value === item.farmer.ac_type_id)
          const acTypeData = { ac_type_name: acTypeName !== undefined ? (this.locale === 'bn' ? acTypeName.text_bn : acTypeName.text_en) : '' }
          const bankObj = this.$store.state.commonObj.bankObj.bankList.find(bank => bank.value === item.farmer.bank_id)
          const bankData = { bank_name: bankObj !== undefined ? (this.locale === 'bn' ? bankObj.text_bn : bankObj.text_en) : '' }
          const branchObj = this.$store.state.commonObj.bankObj.branchList.find(branch => branch.value === item.farmer.branch_id)
          const branchData = { branch_name: branchObj !== undefined ? (this.locale === 'bn' ? branchObj.text_bn : branchObj.text_en) : '' }
          return Object.assign({}, item, deReqObj, accountGroupData, acTypeData, bankData, branchData)
      })
        const fiscalYear = this.$store.state.commonObj.fiscalYearList.find(fsy => fsy.value === data.fiscal_year_id)
        const season = this.$store.state.incentiveGrant.commonObj.seasonSetupList.find(season => season.value === data.season_id)
        const circular = this.$store.state.incentiveGrant.commonObj.circularList.find(cr => cr.value === data.circular_id)
        const district = this.$store.state.commonObj.districtList.find(dst => dst.value === data.district_id)
        const upazilla = this.$store.state.commonObj.upazilaList.find(upz => upz.value === data.upazilla_id)
        const fiscalYearObj = {
          fiscal_year: fiscalYear !== undefined ? fiscalYear.text_en : '',
          fiscal_year_bn: fiscalYear !== undefined ? fiscalYear.text_bn : ''
        }
        const seasonObj = {
          season: season !== undefined ? season.text_en : '',
          season_bn: season !== undefined ? season.text_bn : ''
        }
        const circularObj = {
          circular_name: circular !== undefined ? circular.text_en : '',
          circular_name_bn: circular !== undefined ? circular.text_bn : ''
        }
        const districtObj = {
          district_name: district !== undefined ? district.text_en : '',
          district_name_bn: district !== undefined ? district.text_bn : ''
        }
        const upazillaObj = {
          upazilla_name: upazilla !== undefined ? upazilla.text_en : '',
          upazilla_name_bn: upazilla !== undefined ? upazilla.text_bn : ''
        }
        return Object.assign(data, fiscalYearObj, seasonObj, circularObj, districtObj, upazillaObj)
      }
    }
}
</script>
<style lang="scss">
  .hidden_header {
    display: none;
  }
</style>
